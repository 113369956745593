export default {
  data: () => ({
    loading: false,
    plans: [],
    subscription: []
  }),
  async mounted () {
    await this.getPlans()
    await this.getSubscription()
  },
  methods: {
    async getPlans () {
      this.loading = true

      const url = '/user/controle_infinity/get_plans'
      await this.$http.get(url)
        .then(res => {
          this.plans = res.data
        }).finally(() => {
          this.loading = false
        })
    },
    async getSubscription () {
      this.loading = true

      const url = '/user/controle_infinity/subscription'
      await this.$http.get(url)
        .then(res => {
          this.subscription = res.data
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
