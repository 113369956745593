<template>
  <section>
    <b-card>
      <b-card-title>
        Controle Infinity
      </b-card-title>

      <b-card-text>
        <b-row>
          <b-col cols="12">
            <p>
              Cansado de se perder com suas dezenas ou milhares de contas? Ou de ficar anotando as informações em um pedaço de papel ou bloco de notas? Nós temos a solução!
            </p>

            <p>
              O Controle Infinity veio para resolver de vez seus problemas ao gerenciar suas contas! Tenha tudo organizado e de fácil acesso em segundos!
            </p>

            <div class="d-flex justify-content-center">
              <img
                class="rounded-lg mt-3 mb-3 d-flex"
                width="80%"
                :src="`${$api_host}/storage/screenshots/1.png`"
              >
            </div>

            <p>
              Importe e organize suas contas, filtre e classifique por situações; pesquise e exporte já nos formatos dos principais BOTs tudo com poucos cliques!!
            </p>

            <p>
              Gerencie seus ganhos e tenha um resumo e  visão geral sobre suas contas!
            </p>

            <div class="d-flex justify-content-center">
              <img
                class="rounded-lg mt-3 mb-3 d-flex"
                width="80%"
                :src="`${$api_host}/storage/screenshots/2.png`"
              >
            </div>

            <p>
              Valorize seu tempo e aumente seus ganhos graças a organização, segurança e praticidade que o Controle Infinity tem a te oferecer!
            </p>

            <p>
              Experimente agora e não desperdice mais o seu tempo ao gerenciar e usar suas contas!
            </p>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <b-row class="pricing-card d-flex justify-content-center">
      <b-col cols="4">
        <b-card align="center">
          <div
            v-show="true"
            class="pricing-badge text-center"
          >
            <b-badge
              variant="light-info"
              pill
              class="font-small-4"
            >
              <span class="py-1 px-1">
                TESTE GRATIS DE 1 DIA
              </span>
            </b-badge>
          </div>

          <div class="annual-plan">
            <div class="plan-price mt-2">
              <sup class="font-medium-1 font-weight-bold text-primary">R$ </sup>
              <span
                class="pricing-basic-value font-weight-bolder text-primary"
              >0</span>
            </div>
          </div>

          <b-button
            block
            variant="relief-primary"
            class="mt-1"
            :disabled="loading"
            @click.stop="getStartInFreeTrialConfirm()"
          >
            <span v-if="!loading">Testar Gratuitamente</span>
            <b-spinner
              v-else
              small
            />
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="pricing-card">
      <b-col
        v-for="(item, index) in plans"
        :key="index"
        md="4"
      >
        <b-card align="center">
          <div
            v-show="true"
            class="pricing-badge text-center"
          >
            <b-badge
              variant="light-info"
              pill
              class="font-small-4"
            >
              <span class="py-1 px-1">
                {{ item.plan }}
              </span>
            </b-badge>
          </div>

          <div class="annual-plan">
            <div class="plan-price mt-2">
              <sup class="font-medium-1 font-weight-bold text-primary">R$ </sup>
              <span
                class="pricing-basic-value font-weight-bolder text-primary"
              >{{ item.price }}</span>
            </div>
          </div>

          <b-list-group class="list-group-circle text-left d-flex justify-content-center">
            <b-list-group-item
              v-for="(details) in JSON.parse(item.details)"
              :key="details"
            >
              {{ details }}
            </b-list-group-item>
          </b-list-group>

          <b-button
            block
            variant="relief-primary"
            class="mt-1"
            :disabled="loading"
            @click.stop="purchasePlanConfirm(item)"
          >
            <span v-if="!loading">Comprar</span>
            <b-spinner
              v-else
              small
            />
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BListGroup, BListGroupItem, BCard, BCardTitle, BCardText, BRow, BCol, BBadge, BSpinner, BButton
} from 'bootstrap-vue'
import IndexMixin from './IndexMixin'
import PurchaseMixin from './PurchaseMixin'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BBadge,
    BButton,
    BSpinner
  },
  mixins: [IndexMixin, PurchaseMixin]
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
