import { mapActions } from 'vuex'
import SwalMessages from '@/views/pages/BuyServices/utils/SwalMessages'

export default {
  data: () => ({
    loading: false
  }),
  methods: {
    ...mapActions('user', ['addAbility', 'removeAbility', 'updateLocalStorage']),
    getStartInFreeTrialConfirm () {
      this.$swal({
        title: '<strong>Iniciar avaliação gratuita?</strong>',
        type: 'info',
        html: '<p>Você será redirecionado para a página de avaliação gratuita.</p>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Iniciar avaliação!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        }
      }).then(result => {
        if (result.value) {
          this.getStartInFreeTrial()
        }
      })
    },
    async getStartInFreeTrial () {
      this.loading = true
      const url = '/user/controle_infinity/free_trial'

      await this.$http.get(url)
        .then(async res => {
          if (res.status === 201 && res.data === 'Free trial applied') {
            await this.addAbility('controle.all')
            await this.removeAbility('controle.buy')
            await this.updateLocalStorage()

            this.$swal({
              title: 'Sucesso!',
              icon: 'success',
              text: 'Você está no modo de teste gratuito!',
              type: 'success',
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
              .then(confirmed => {
                if (confirmed) {
                  this.$router.push({ name: 'Controle Infinity (Minha Assinatura)' })
                }
              })
          }
        })
        .catch(() => {
          this.$swal({
            title: 'Erro!',
            icon: 'error',
            text: 'Você não pode iniciar uma assinatura gratuita!',
            type: 'error',
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-danger'
            }
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    purchasePlanConfirm (plan) {
      this.$swal({
        title: '<strong>Assinar Plano?</strong>',
        type: 'info',
        html: `<p>Você está prestes a comprar o plano <strong>${plan.plan}</strong>.</p>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        }
      }).then(result => {
        if (result.value) {
          this.purchasePlan(plan)
        }
      })
    },
    async purchasePlan (plan) {
      const url = `/user/controle_infinity/purchase/${plan.id}`
      this.loading = true

      this.$http.get(url)
        .then(async res => {
          if (res.status === 201 && res.data === 'Plan applied') {
            await this.addAbility('controle.all')
            await this.removeAbility('controle.buy')
            await this.updateLocalStorage()

            this.$swal({
              title: 'Sucesso!',
              icon: 'success',
              text: 'Sua assinatura está ativa!',
              type: 'success',
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
              .then(confirmed => {
                if (confirmed) {
                  this.$router.push({ name: 'Controle Infinity (Minha Assinatura)' })
                }
              })
          }
          if (res.data.message === 'no balance.' && res.status === 200) this.noBalance()
        })
        .finally(() => {
          this.loading = false
        })
    },
    noBalance () {
      this.$swal(SwalMessages.no_balance).then(buttons => (buttons.isConfirmed
        ? this.$router.push({ name: 'Adicionar Saldo' })
        : null))
    }
  }
}
